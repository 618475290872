import { CreatePackageRequest, Package } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _PackageService {
  public getPackages() {
    return HttpService.get<Package[]>("/packages");
  }

  public createPackage(
    categoryId: string,
    createPackageRequest: CreatePackageRequest
  ) {
    return HttpService.post<Package>(`/packages/categories/${categoryId}`, {
      body: {
        ...createPackageRequest,
      },
    });
  }

  public deletePackage(packageId: string) {
    return HttpService.delete<Package>(`/packages/${packageId}`);
  }
}

const PackageService = new _PackageService();

export default PackageService;
