import Input from "@app/components/input";
import Pagination from "@app/components/pagination";
import { Select } from "@app/components/select/select";
import {
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
  FilterRechargeStatusOptions,
  RechargeState,
  RechargeStateName,
} from "@app/constants";
import RechargeService from "@app/services/http/recharge.service";
import { Recharge } from "@app/types";
import { formatDate, formatMoneyVN } from "@app/utils/util";
import useObservable from "@core/hooks/use-observable.hook";
import clsx from "clsx";
import { ChangeEvent, useEffect, useState } from "react";
import { Subject, debounceTime } from "rxjs";

function RechargeManagement() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [recharges, setRecharges] = useState<Recharge[]>([]);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterIsSucceeded, setFilterIsSucceeded] = useState<RechargeState>();

  const searchSubject = new Subject<string>();
  const fromDateSubject = new Subject<string>();
  const toDateSubject = new Subject<string>();

  const { subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    searchSubject.pipe(debounceTime(300)).subscribe((data) => {
      setSearch(data);
    });
    fromDateSubject.pipe(debounceTime(300)).subscribe((data) => {
      setFromDate(data);
    });
    toDateSubject.pipe(debounceTime(300)).subscribe((data) => {
      setToDate(data);
    });
  }, [searchSubject, fromDateSubject, toDateSubject]);

  useEffect(() => {
    subscribeUntilDestroy(
      RechargeService.getRecharges(
        page,
        search,
        fromDate,
        toDate,
        filterIsSucceeded
      ),
      (res) => {
        if (res.data.length) {
          setRecharges(res.data);
          setTotalPages(res.total_page);
        } else {
          setRecharges([]);
          setTotalPages(DEFAULT_TOTAL_PAGES);
        }
      }
    );
  }, [page, search, fromDate, toDate, filterIsSucceeded]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    searchSubject.next(e.target.value);
  };

  const onFromDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    fromDateSubject.next(e.target.value);
  };

  const onToDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    toDateSubject.next(e.target.value);
  };

  const handleFilterRechargeStatusChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    setFilterIsSucceeded(e.target.value as any);
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="max-sm:flex-col flex gap-3 pb-2 px-2 border-b border-[#409EFF]">
        <Input
          className="mt-0 max-w-[320px]"
          placeholder="Nhập username hoặc nội dung"
          onChange={onFilterChange}
        />
        <Input
          type="date"
          className="mt-0 max-w-[250px]"
          placeholder="Nhập Ngày"
          onChange={onFromDateChange}
        />
        <Input
          type="date"
          className="mt-0 max-w-[250px]"
          placeholder="Đến Ngày"
          onChange={onToDateChange}
        />
        <Select
          width={110}
          className="h-6 !mt-0"
          options={FilterRechargeStatusOptions}
          value={filterIsSucceeded}
          defaultValue={-1}
          onChange={handleFilterRechargeStatusChange}
        />
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-52">Tài khoản</th>
                <th className="p-3 text-left w-52">Email</th>
                <th className="p-3 text-left w-40">Số điện thoại</th>
                <th className="p-3 text-left w-40">Số tiền</th>
                <th className="p-3 text-left w-56">Nội dung</th>
                <th className="p-3 text-left w-40">Ngân hàng nhận</th>
                <th className="p-3 text-left w-40">Thời gian nạp</th>
                <th className="p-3 text-left w-40 min-w-[120px]">Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              {!!recharges.length &&
                recharges.map((recharge) => (
                  <tr
                    key={recharge._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">{recharge.account?.username}</td>
                    <td className="p-3">{recharge.account?.email}</td>
                    <td className="p-3">{recharge.account?.phone}</td>
                    <td className="p-3">{formatMoneyVN(recharge.money)}đ</td>
                    <td className="p-3">{recharge.content}</td>
                    <td className="p-3">{recharge.bank}</td>
                    <td className="p-3">{formatDate(recharge.createdAt)}</td>
                    <td className="p-3">
                      <span
                        className={clsx("px-2 py-1 rounded-lg", {
                          "bg-yellow-500":
                            recharge.is_succeeded === RechargeState.PENDING,
                          "bg-green-500":
                            recharge.is_succeeded ===
                            RechargeState.SUCCESSFULLY,
                          "bg-red-500":
                            recharge.is_succeeded === RechargeState.CANCELLED,
                          "bg-orange-500":
                            recharge.is_succeeded === RechargeState.TIMEOUT,
                        })}
                      >
                        {RechargeStateName[recharge.is_succeeded]}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!recharges.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
      {totalPages > 0 && (
        <div className="flex justify-center mt-3">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default RechargeManagement;
