import CreateBankModal from "@app/components/bank-modal";
import Button from "@app/components/button";
import ConfirmModal from "@app/components/confirm-modal";
import { addToast } from "@app/components/toast/toast.service";
import { SystemMessage } from "@app/constants";
import BankService from "@app/services/http/bank.service";
import { openPortalDialog } from "@app/services/modal.service";
import { Bank } from "@app/types";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";

function BankManagement() {
  const [banks, setBanks] = useState<Bank[]>([]);

  const [update, forceUpdate] = useForceUpdate();
  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(BankService.getBanks(), (banks) => {
      if (banks.length) {
        setBanks(banks);
      } else {
        setBanks([]);
      }
    });
  }, [update]);

  const handleCreateBank = () => {
    const createBankModalObs = openPortalDialog(CreateBankModal);

    createBankModalObs.afterClosed().subscribe((data) => {
      if (data?.bank) {
        addToast({ text: SystemMessage.ADD_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  const handleDeleteBank = (bankId: string) => {
    const deleteBankObs = openPortalDialog(ConfirmModal, {
      message: "Bạn có chắc chắn xóa ngân hàng này không?",
    });

    deleteBankObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        subscribeOnce(BankService.deleteBank(bankId), () => {
          addToast({ text: SystemMessage.DELETE_SUCCESSFULLY });
          forceUpdate();
        });
      }
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="pb-2 px-2 border-b border-[#409EFF]">
        <div className="flex justify-end">
          <Button
            label="Thêm ngân hàng"
            width="fit-content"
            className="px-2 text-sm"
            onClick={handleCreateBank}
          />
        </div>
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-60">Tên ngân hàng</th>
                <th className="p-3 text-left w-72">Số tài khoản</th>
                <th className="p-3 text-left w-72">Chủ tài khoản</th>
                <th className="p-3 text-left w-40">Hành động</th>
              </tr>
            </thead>
            <tbody>
              {!!banks.length &&
                banks.map((bank) => (
                  <tr
                    key={bank._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">{bank.bank_name}</td>
                    <td className="p-3">{bank.bank_number}</td>
                    <td className="p-3">{bank.bank_owner}</td>
                    <td className="p-3">
                      <Button
                        label="Xóa ngân hàng"
                        width="fit-content"
                        className="px-2 text-sm"
                        onClick={() => handleDeleteBank(bank._id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!banks.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BankManagement;
