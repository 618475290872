import Input from "@app/components/input";
import Pagination from "@app/components/pagination";
import { DEFAULT_PAGE, DEFAULT_TOTAL_PAGES } from "@app/constants";
import IPService from "@app/services/http/ip.service";
import { IP } from "@app/types";
import { formatDate } from "@app/utils/util";
import useObservable from "@core/hooks/use-observable.hook";
import { ChangeEvent, useEffect, useState } from "react";
import { Subject, debounceTime } from "rxjs";

function IPManagement() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [ips, setIPs] = useState<IP[]>([]);
  const [username, setUsername] = useState("");
  const [ipv4, setIPv4] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const usernameSubject = new Subject<string>();
  const ipv4Subject = new Subject<string>();
  const fromDateSubject = new Subject<string>();
  const toDateSubject = new Subject<string>();

  const { subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    usernameSubject.pipe(debounceTime(300)).subscribe((data) => {
      setUsername(data);
    });
    ipv4Subject.pipe(debounceTime(300)).subscribe((data) => {
      setIPv4(data);
    });
    fromDateSubject.pipe(debounceTime(300)).subscribe((data) => {
      setFromDate(data);
    });
    toDateSubject.pipe(debounceTime(300)).subscribe((data) => {
      setToDate(data);
    });
  }, [usernameSubject, ipv4Subject, fromDateSubject, toDateSubject]);

  useEffect(() => {
    subscribeUntilDestroy(
      IPService.getIPs(page, username, ipv4, fromDate, toDate),
      (res) => {
        if (res.data.length) {
          setIPs(res.data);
          setTotalPages(res.total_page);
        } else {
          setIPs([]);
          setTotalPages(DEFAULT_TOTAL_PAGES);
        }
      }
    );
  }, [page, username, ipv4, fromDate, toDate]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    usernameSubject.next(e.target.value);
  };

  const onIPv4Change = (e: ChangeEvent<HTMLInputElement>) => {
    ipv4Subject.next(e.target.value);
  };

  const onFromDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    fromDateSubject.next(e.target.value);
  };

  const onToDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    toDateSubject.next(e.target.value);
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="max-sm:flex-col flex gap-3 pb-2 px-2 border-b border-[#409EFF]">
        <Input
          className="mt-0 max-w-[250px]"
          placeholder="Nhập Username"
          onChange={onUsernameChange}
        />
        <Input
          className="mt-0 max-w-[250px]"
          placeholder="Nhập IPv4"
          onChange={onIPv4Change}
        />
        <Input
          type="date"
          className="mt-0 max-w-[250px]"
          placeholder="Nhập Ngày"
          onChange={onFromDateChange}
        />
        <Input
          type="date"
          className="mt-0 max-w-[250px]"
          placeholder="Đến Ngày"
          onChange={onToDateChange}
        />
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-40">IP</th>
                <th className="p-3 text-left w-40">Account</th>
                <th className="p-3 text-left w-40">IPv4</th>
                <th className="p-3 text-left w-40">Thời gian</th>
              </tr>
            </thead>
            <tbody>
              {!!ips.length &&
                ips.map((ip) => (
                  <tr
                    key={ip._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">{ip.ip}</td>
                    <td className="p-3">{ip.account}</td>
                    <td className="p-3">{ip.ipv4}</td>
                    <td className="p-3">{formatDate(ip.createdAt)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!ips.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
      {totalPages > 0 && (
        <div className="flex justify-center mt-3">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default IPManagement;
