import { MenuItem } from "@app/types";
import { Images } from "@assets/images";

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DEFAULT_MINUTES_SECONDS_FORMAT = "mm:ss";

export const DEFAULT_PAGE = 1;
export const DEFAULT_TOTAL_PAGES = 1;

export const ACCESS_TOKEN_KEY = "access_token";
export const CURRENT_ACCOUNT = "current_account";

export enum Role {
  ADMIN = "admin",
  STAFF = "staff",
  USER = "user",
}

export enum PathnameUrl {
  ACCOUNT_MANAGEMENT = "/",
  BANK_MANAGEMENT = "/bank-management",
  RECHARGE_MANAGEMENT = "/recharge-management",
  CATEGORY_MANAGEMENT = "/category-management",
  PACKAGE_MANAGEMENT = "/package-management",
  PC_MANAGEMENT = "/pc-management",
  ORDER_MANAGEMENT = "/order-management",
  LOG_MANAGEMENT = "/log-management",
  IP_MANAGEMENT = "/ip-management",
  IMAGE_MANAGEMENT = "/image-management",
  SSH_MANAGEMENT = "/ssh-management",
  USER_GUIDE_MANAGEMENT = "/user-guide-management",
}

export enum LabelAdmin {
  ACCOUNT_MANAGEMENT = "Account Management",
  BANK_MANAGEMENT = "Bank Management",
  RECHARGE_MANAGEMENT = "Recharge Management",
  CATEGORY_MANAGEMENT = "Category Management",
  PACKAGE_MANAGEMENT = "Package Management",
  PC_MANAGEMENT = "PC Management",
  ORDER_MANAGEMENT = "Order Management",
  LOG_MANAGEMENT = "Log Management",
  IP_MANAGEMENT = "IP Management",
  IMAGE_MANAGEMENT = "Image Management",
  SSH_MANAGEMENT = "SSH Management",
  USER_GUIDE_MANAGEMENT = "Hướng Dẫn Sử Dụng",
}

export const MenuAdmin: readonly MenuItem[] = [
  {
    linkTo: PathnameUrl.ACCOUNT_MANAGEMENT,
    icon: Images.Group.default,
    label: LabelAdmin.ACCOUNT_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.BANK_MANAGEMENT,
    icon: Images.BankBuilding.default,
    label: LabelAdmin.BANK_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.RECHARGE_MANAGEMENT,
    icon: Images.Money.default,
    label: LabelAdmin.RECHARGE_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.CATEGORY_MANAGEMENT,
    icon: Images.Category.default,
    label: LabelAdmin.CATEGORY_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.PACKAGE_MANAGEMENT,
    icon: Images.Package.default,
    label: LabelAdmin.PACKAGE_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.PC_MANAGEMENT,
    icon: Images.Computer.default,
    label: LabelAdmin.PC_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.ORDER_MANAGEMENT,
    icon: Images.Clipboard.default,
    label: LabelAdmin.ORDER_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.LOG_MANAGEMENT,
    icon: Images.Log.default,
    label: LabelAdmin.LOG_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.IP_MANAGEMENT,
    icon: Images.IPAddressIcon.default,
    label: LabelAdmin.IP_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.IMAGE_MANAGEMENT,
    icon: Images.Package.default,
    label: LabelAdmin.IMAGE_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.SSH_MANAGEMENT,
    icon: Images.IPAddressIcon.default,
    label: LabelAdmin.SSH_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.USER_GUIDE_MANAGEMENT,
    icon: Images.IPAddressIcon.default,
    label: LabelAdmin.USER_GUIDE_MANAGEMENT,
  },
];

export const MenuStaff: readonly MenuItem[] = [
  {
    linkTo: PathnameUrl.ORDER_MANAGEMENT,
    icon: Images.Clipboard.default,
    label: LabelAdmin.ORDER_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.PC_MANAGEMENT,
    icon: Images.Computer.default,
    label: LabelAdmin.PC_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.LOG_MANAGEMENT,
    icon: Images.Log.default,
    label: LabelAdmin.LOG_MANAGEMENT,
  },
];
