import Button from "@app/components/button"; 
import ConfirmModal from "@app/components/confirm-modal";
import Input from "@app/components/input";
import Pagination from "@app/components/pagination";
import { addToast } from "@app/components/toast/toast.service";
import {
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
  SystemMessage,
} from "@app/constants";
import ImageService from "@app/services/http/image.service";
import { openPortalDialog } from "@app/services/modal.service";
import { Image } from "@app/types";
import { formatDate } from "@app/utils/util";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { ChangeEvent, useEffect, useState } from "react";
import { Subject, debounceTime } from "rxjs";

function ImageManagement() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [images, setImages] = useState<Image[]>([]);
  const [username, setUsername] = useState("");
  const [imageID, setImageID] = useState("");
  const [group, setGroup] = useState("");
  const [status, setStatus] = useState("");

  const usernameSubject = new Subject<string>();
  const imageIdSubject = new Subject<string>();
  const groupSubject = new Subject<string>();
  const statusSubject = new Subject<string>();

  const [update, forceUpdate] = useForceUpdate();
  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    usernameSubject.pipe(debounceTime(300)).subscribe((data) => {
      setUsername(data);
    });

    imageIdSubject.pipe(debounceTime(300)).subscribe((data) => {
      setImageID(data);
    });

    groupSubject.pipe(debounceTime(300)).subscribe((data) => {
      setGroup(data);
    });

    statusSubject.pipe(debounceTime(300)).subscribe((data) => {
      setStatus(data);
    });
  }, [usernameSubject, imageIdSubject, groupSubject, statusSubject]);

  useEffect(() => {
    subscribeUntilDestroy(
      ImageService.getImages(page, username, imageID, group, status),
      (res) => {
        if (res.data.length) {
          setImages(res.data);
          setTotalPages(res.total_page);
        } else {
          setImages([]);
          setTotalPages(DEFAULT_TOTAL_PAGES);
        }
      }
    );
  }, [page, username, imageID, group, update, status]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    usernameSubject.next(e.target.value);
  };

  const onImageIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    imageIdSubject.next(e.target.value);
  };

  const onGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
    groupSubject.next(e.target.value);
  };

  const onStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    const status = e.target.value;

    // if (status === "0" || status === "1" || status === "2") {
    statusSubject.next(status);
    // }
  };

  const deleteImage = (id_image: string) => {
    const deleteImageObs = openPortalDialog(ConfirmModal, {
      message: "Bạn có chắc chắn xóa Image này không?",
    });

    deleteImageObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        subscribeOnce(ImageService.deleteImage(id_image), () => {
          addToast({ text: SystemMessage.DELETE_SUCCESSFULLY });
          forceUpdate();
        });
      }
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="flex gap-2 pb-2 px-2 border-b border-[#409EFF]">
        <Input
          className="mt-0 max-w-[320px]"
          placeholder="Nhập username"
          onChange={onUsernameChange}
        />
        <Input
          className="mt-0 max-w-[320px]"
          placeholder="Nhập id image"
          onChange={onImageIdChange}
        />
        <Input
          className="mt-0 max-w-[320px]"
          placeholder="Nhập group"
          onChange={onGroupChange}
        />
        <Input
          className="mt-0 max-w-[320px]"
          placeholder="0 chờ boot, 1 đang boot, 2 đã boot"
          onChange={onStatusChange}
        />
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-32">ID</th>
                <th className="p-3 text-left w-32">User</th>
                <th className="p-3 text-left w-32">Group</th>
                <th className="p-3 text-left w-32">Trạng thái</th>
                <th className="p-3 text-left w-40">Thời gian tạo</th>
                <th className="p-3 text-left w-40">Chức năng</th>
              </tr>
            </thead>
            <tbody>
              {!!images.length &&
                images.map((image) => (
                  <tr
                    key={image._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">{image._id}</td>
                    <td className="p-3 whitespace-pre-line">
                      {image.account?.username}
                    </td>
                    <td className="p-3">{image.package?.group}</td>
                    <td className="p-3">
                      {image.is_booting
                        ? "Đang boot"
                        : image.is_installed
                        ? "Đã boot"
                        : "Chờ boot"}
                    </td>
                    <td className="p-3">{formatDate(image.createdAt)}</td>
                    <td>
                      <Button
                        label="Xóa"
                        onClick={() => deleteImage(image._id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!images.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
      {totalPages > 0 && (
        <div className="flex justify-center mt-3">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default ImageManagement;
