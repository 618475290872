import { DEFAULT_PAGE, RechargeState } from "@app/constants";
import { RechargeResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _RechargeService {
  public getRecharges(
    page = DEFAULT_PAGE,
    search = "",
    fromDate = "",
    toDate = "",
    is_succeeded?: RechargeState
  ) {
    const queryParams: any = {
      page,
      search,
      fromDate,
      toDate,
    };

    if (typeof is_succeeded === "string" && is_succeeded !== "-1") {
      queryParams.is_succeeded = is_succeeded;
    }

    return HttpService.get<RechargeResponse>("/recharges", {
      queryParams,
    });
  }
}

const RechargeService = new _RechargeService();

export default RechargeService;
