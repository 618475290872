import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import Input from "../input";
import Button from "../button";
import { CategoryInitialValues } from "@app/types";
import { createRef } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import { createCategoryValidationSchema } from "@app/validations";
import CategoryService from "@app/services/http/category.service";

function CreateCategoryModal({ portalDialogRef }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<CategoryInitialValues>>();

  const { subscribeOnce } = useObservable();

  const handleSubmit = (values: CategoryInitialValues) => {
    if (values.name) {
      subscribeOnce(CategoryService.createCategory(values.name), (category) => {
        portalDialogRef.close({ category });
      });
    }
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] p-5">
        <div className="font-bold text-xl">Thêm danh mục</div>
        <Formik
          initialValues={{ name: "" }}
          validationSchema={createCategoryValidationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="mt-3">
              <div className="text-sm">Tên danh mục</div>
              <FormControl name="name">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <Button
              type="submit"
              label="Thêm"
              containerClassName="mt-5 mx-auto"
              width="fit-content"
              className="px-6 text-sm"
              labelClassName="font-bold"
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default CreateCategoryModal;
