import Button from "@app/components/button";
import ConfirmModal from "@app/components/confirm-modal";
import CreatePackageModal from "@app/components/package-modal";
import { addToast } from "@app/components/toast/toast.service";
import { SystemMessage } from "@app/constants";
import PackageService from "@app/services/http/package.service";
import { openPortalDialog } from "@app/services/modal.service";
import { Package } from "@app/types";
import { formatMoneyVN } from "@app/utils/util";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";

function PackageManagement() {
  const [packages, setPackages] = useState<Package[]>([]);

  const [update, forceUpdate] = useForceUpdate();

  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(PackageService.getPackages(), (packages) => {
      if (packages.length) {
        setPackages(packages);
      } else {
        setPackages([]);
      }
    });
  }, [update]);

  const handleCreatePackage = () => {
    const createPackageModalObs = openPortalDialog(CreatePackageModal);

    createPackageModalObs.afterClosed().subscribe((data) => {
      if (data?.packageRes) {
        addToast({ text: SystemMessage.ADD_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  const handleDeletePackage = (packageId: string) => {
    const deletePackageObs = openPortalDialog(ConfirmModal, {
      message: "Bạn có chắc chắn xóa gói này không?",
    });

    deletePackageObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        subscribeOnce(PackageService.deletePackage(packageId), () => {
          addToast({ text: SystemMessage.DELETE_SUCCESSFULLY });
          forceUpdate();
        });
      }
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="pb-2 px-2 border-b border-[#409EFF]">
        <div className="flex justify-end">
          <Button
            label="Thêm gói"
            width="fit-content"
            className="px-2 text-sm"
            onClick={handleCreatePackage}
          />
        </div>
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-52">Tên nhóm / ID</th>
                <th className="p-3 text-left w-72">Tên gói</th>
                <th className="p-3 text-left w-72">Cấu hình</th>
                <th className="p-3 text-left w-56">Giá theo giờ (VNĐ)</th>
                <th className="p-3 text-left w-56">Giá theo ngày (VNĐ)</th>
                <th className="p-3 text-left w-56">Giá theo tháng (VNĐ)</th>
                <th className="p-3 text-left w-52">Danh mục</th>
                <th className="p-3 text-left w-52">Hành động</th>
              </tr>
            </thead>
            <tbody>
              {!!packages.length &&
                packages.map((item) => (
                  <tr
                    key={item._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">
                      {item.group} / {item._id}
                    </td>
                    <td className="p-3">{item.name}</td>
                    <td className="p-3">
                      <div>
                        <span className="font-semibold">CPU: </span>
                        {item.cpu}
                      </div>
                      <div>
                        <span className="font-semibold">GPU: </span>
                        {item.gpu}
                      </div>
                      <div>
                        <span className="font-semibold">RAM: </span>
                        {item.ram}
                      </div>
                      <div>
                        <span className="font-semibold">SSD: </span>
                        {item.ssd}
                      </div>
                      <div>
                        <span className="font-semibold">OS: </span>
                        {item.os}
                      </div>
                    </td>
                    <td className="p-3">
                      {item.price_per_hour &&
                        formatMoneyVN(item.price_per_hour)}
                    </td>
                    <td className="p-3">
                      {item.price_per_day && formatMoneyVN(item.price_per_day)}
                    </td>
                    <td className="p-3">
                      {item.price_per_month &&
                        formatMoneyVN(item.price_per_month)}
                    </td>
                    <td className="p-3">{item.category?.name}</td>
                    <td className="p-3">
                      <Button
                        label="Xóa gói"
                        width="fit-content"
                        className="px-2 text-sm"
                        onClick={() => handleDeletePackage(item._id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!packages.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PackageManagement;
