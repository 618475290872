import { DEFAULT_PAGE } from "@app/constants";
import { LogResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _LogService {
  public getLogs(page = DEFAULT_PAGE, search = "") {
    return HttpService.get<LogResponse>("/logs", {
      queryParams: {
        page,
        search,
      },
    });
  }
}

const LogService = new _LogService();

export default LogService;
