import { Ssh, CreateSshRequest } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _SshService {
  public getSshs() {
    return HttpService.get<Ssh[]>("/sshs");
  }

  public createSsh(createSshRequest: CreateSshRequest) {
    return HttpService.post<Ssh>("/sshs", {
      body: {
        ...createSshRequest,
      },
    });
  }

  public updateSsh(sshId: string, updateSshRequest: CreateSshRequest) {
    return HttpService.patch<Ssh>(`/sshs/${sshId}`, {
      body: {
        ...updateSshRequest,
      },
    });
  }

  public deleteSsh(sshId: string) {
    return HttpService.delete<Ssh>(`/sshs/${sshId}`);
  }
}

const SshService = new _SshService();

export default SshService;
