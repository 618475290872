import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import Input from "../input";
import Button from "../button";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import { createRef } from "react";
import { ReasonKycInitialValues } from "@app/types";
import { reasonKycValidationSchema } from "@app/validations";

function ReasonKycModal({ portalDialogRef }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<ReasonKycInitialValues>>();

  const handleSubmit = (values: ReasonKycInitialValues) => {
    portalDialogRef.close({ reason: values.reason });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] p-5">
        <div>Lý do thất bại</div>
        <Formik
          initialValues={{ reason: "" }}
          onSubmit={handleSubmit}
          validationSchema={reasonKycValidationSchema}
          innerRef={formRef}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <FormControl name="reason">
              <Input
                className="mt-1 !max-w-none text-sm"
                errorClassName="text-sm"
              />
            </FormControl>
            <div>
              <Button
                type="submit"
                containerClassName="mt-3 mx-auto"
                label="Gửi"
                size="xs"
                width="fit-content"
                className="px-6 text-sm"
                labelClassName="font-bold"
              />
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default ReasonKycModal;
