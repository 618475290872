import { combineReducers, legacy_createStore } from "redux";
import { authReducer, AuthState } from "./auth";
// import { myInfoReducer, MyInfoState } from "./my-info";
import { myInfoReducer } from "./my-info/my-info.reducer";
import { MyInfoState } from "./my-info/my-info.type";

const rootReducer = combineReducers<GlobalState>({
  auth: authReducer,
  myInfo: myInfoReducer,
});

const store = legacy_createStore(rootReducer);

export interface GlobalState {
  auth: AuthState;
  myInfo: MyInfoState;
}

export default store;
