import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";

function ViewImageModal({
  portalDialogRef,
  portalData,
}: PortalDialogProps<{ href: string }>) {
  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="p-12">
        <img
          className="max-w-3xl max-h-[600px]"
          src={portalData?.href}
          alt=""
        />
      </div>
    </Modal>
  );
}

export default ViewImageModal;
