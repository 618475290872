import { DEFAULT_PAGE } from "@app/constants";
import {
  Account,
  AccountResponse,
  CreateAccountRequest,
  ImageAllowedRequest,
  UpdateBalanceRequest,
  VerifyAccountRequest,
} from "@app/types";
import HttpService from "@core/services/http/http.service";

class _AccountService {
  public createAccount(createAccountRequest: CreateAccountRequest) {
    return HttpService.post<Account>("/accounts", {
      body: { ...createAccountRequest },
    });
  }

  public getAccounts(page = DEFAULT_PAGE, search = "") {
    return HttpService.get<AccountResponse>("/accounts", {
      queryParams: {
        page,
        search,
      },
    });
  }

  public verifyAccount(
    accountId: string,
    verifyAccountRequest: VerifyAccountRequest
  ) {
    return HttpService.patch<Account>(`/accounts/${accountId}`, {
      body: { ...verifyAccountRequest },
    });
  }

  public updateImageAllowed(
    accountId: string,
    imageAllowedRequest: ImageAllowedRequest
  ) {
    return HttpService.patch<Account>(`/accounts/image-allowed/${accountId}`, {
      body: { ...imageAllowedRequest },
    });
  }

  public updateBalance(
    accountId: string,
    updateBalanceRequest: UpdateBalanceRequest
  ) {
    return HttpService.patch<Account>(`/accounts/balance/${accountId}`, {
      body: { ...updateBalanceRequest },
    });
  }
}

const AccountService = new _AccountService();

export default AccountService;
