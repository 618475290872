import Button from "@app/components/button";
import { FormControl } from "@app/components/form-control";
import Input from "@app/components/input";
import { addToast } from "@app/components/toast/toast.service";
import {
  ACCESS_TOKEN_KEY,
  CURRENT_ACCOUNT,
  Role,
  SystemMessage,
} from "@app/constants";
import AuthService from "@app/services/http/auth.service";
import { storeUser } from "@app/store/auth/auth.action";
import { LoginInitialValues } from "@app/types";
import { loginValidationSchema } from "@app/validations";
import { Images } from "@assets/images";
import useObservable from "@core/hooks/use-observable.hook";
import StorageService from "@core/services/storage";
import { Form, Formik, FormikContextType } from "formik";
import { createRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const formRef = createRef<FormikContextType<LoginInitialValues>>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscribeOnce } = useObservable();

  const handleSubmit = (values: LoginInitialValues) => {
    subscribeOnce(
      AuthService.login(values.username, values.password),
      (loginRes) => {
        if (loginRes.role === Role.ADMIN || loginRes.role === Role.STAFF) {
          StorageService.set(ACCESS_TOKEN_KEY, loginRes.jwt);
          StorageService.setObject(CURRENT_ACCOUNT, loginRes);
          dispatch(storeUser(loginRes));
          loginRes.role === Role.STAFF
            ? navigate("/order-management")
            : navigate("/");
        } else {
          addToast({
            text: SystemMessage.DO_NOT_PERMISSION,
            status: "inValid",
          });
        }
      }
    );
  };

  return (
    <div className="flex relative h-screen w-full bg-cover bg-no-repeat bg-center bg-[url(@assets/images/background.jpg)]">
      <div className="absolute h-screen w-full bg-black opacity-30"></div>
      <div className="flex w-full z-10">
        <div className="flex-1">
          <div className="h-full mx-auto flex-col flex items-center justify-center">
            <div className="bg-[#FFFFFFDA] px-4 py-2 rounded-[10px]">
              <div className="p-4">
                <div className="mb-[7px] mt-3">
                  <img
                    className="w-48 mx-auto"
                    src={Images.LogoTextIcon.default}
                    alt=""
                  />
                </div>
                <div className="text-[12.8px] text-[#6C757D] mt-1 text-center mb-7">
                  High-Performance GPU Computer Rental Service
                </div>
                <Formik
                  initialValues={{ username: "", password: "" }}
                  onSubmit={handleSubmit}
                  innerRef={formRef}
                  validationSchema={loginValidationSchema}
                  validateOnChange
                  validateOnBlur
                >
                  <Form>
                    <FormControl name="username">
                      <Input
                        width="auto"
                        className="!w-[376px] !max-w-none mb-[22px] text-sm"
                        inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                        placeholder="Email or username..."
                        inputElement={
                          <i className="fa fa-user text-[#c0c4cc]"></i>
                        }
                        errorClassName="text-[#F56C6C] text-xs"
                      />
                    </FormControl>
                    <FormControl name="password">
                      <Input
                        width="auto"
                        className="!w-[376px] !max-w-none mb-[22px] text-sm"
                        inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                        placeholder="Type your password..."
                        inputElement={
                          <i className="fa fa-lock text-[#c0c4cc]"></i>
                        }
                        errorClassName="text-[#F56C6C] text-xs"
                        type="password"
                      />
                    </FormControl>
                    <div className="mt-3 text-sm text-[#409EFF] text-right">
                      Forgotten account?
                    </div>
                    <Button
                      type="submit"
                      width="auto"
                      size="m"
                      containerClassName="mt-7"
                      label="LOGIN"
                      className="text-sm rounded-[20px] bg-[#409EFF] border-[#1E86E5] hover:bg-[#66B1FF] hover:border-[#66B1FF]"
                      labelClassName="font-medium"
                    />
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
