import * as Yup from "yup";

export const reasonKycValidationSchema = Yup.object().shape({
  reason: Yup.string().required("Lý do thất bại không được để trống"),
});

export const rechargeWithdrawValidationSchema = Yup.object().shape({
  money: Yup.number()
    .required("Số tiền không được để trống")
    .integer("Số tiền không hợp lệ"),
  content: Yup.string().required("Nội dung không được để trống"),
});

export const createAccountValidationSchema = Yup.object().shape({
  username: Yup.string().required("Username không được để trống"),
  password: Yup.string().required("Mật khẩu không được để trống"),
  email: Yup.string().required("Email không được để trống"),
  fullName: Yup.string().required("Họ tên không được để trống"),
  phone: Yup.string().required("SĐT không được để trống"),
  role: Yup.string().required("Hãy chọn quyền hạn"),
  serversAccess: Yup.array()
    .min(1)
    .of(Yup.string().required("Trường này là bắt buộc"))
    .required("Hãy chọn ít nhất một server"),
});
