import { MenuItem } from "@app/types";
import { NavLink } from "react-router-dom";

interface MenuItemProps extends MenuItem {
  onMenuItemClick: () => void;
}

function MenuLinkItem({ linkTo, label, icon, onMenuItemClick }: MenuItemProps) {
  return (
    <NavLink
      to={linkTo}
      onClick={onMenuItemClick}
      className={({ isActive }) => {
        let className = "h-14 px-6 flex items-center hover:bg-[#ecf5ff] ";

        if (isActive) {
          className +=
            "text-[#409EFF] bg-[#ecf5ff] border-l-4 border-solid border-[#409EFF] !pl-[20px] ";
        }

        return className;
      }}
    >
      <div className="mr-[15px]">
        <img className="w-6" src={icon} alt="" />
      </div>
      <div className="text-sm">{label}</div>
    </NavLink>
  );
}

export default MenuLinkItem;
