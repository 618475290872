import * as Yup from "yup";

export const createSshValidationSchema = Yup.object().shape({
  ip_address: Yup.string().required("ip không được để trống"),
  username: Yup.string().required("username không được để trống"),
  password: Yup.string().required("password không được để trống"),
  router: Yup.string()
    .required("router không được để trống")
    .test(
      "is-valid-format",
      "router phải theo định dạng wan - vnpt - 1000 đ, wan - vnpt - 1000 đ",
      (value) => {
        const elements = value.split(", ");
        //tìm xem có gặp lỗi nào k
        const isOk = elements.some((v) => {
          const part = v.split(" - ");

          if (part.length !== 3) {
            return true;
          }

          //1000 đ
          const price = part[2].split(" ");

          if (price.length !== 2) {
            return true;
          }

          //nếu k phải số, lỗi  => true
          if (!isFinite(+price[0])) {
            return true;
          }

          return false;
        });

        return !isOk;
      }
    ),
  router_xoay: Yup.string()
    .required("router xoay không được để trống")
    .test(
      "is-valid-format",
      "router phải theo định dạng wan - vnpt - 1000 đ, wan - vnpt - 1000 đ",
      (value) => {
        const elements = value.split(", ");
        //tìm xem có gặp lỗi nào k
        const isOk = elements.some((v) => {
          const part = v.split(" - ");

          if (part.length !== 3) {
            return true;
          }

          //1000 đ
          const price = part[2].split(" ");

          if (price.length !== 2) {
            return true;
          }

          //nếu k phải số, lỗi  => true
          if (!isFinite(+price[0])) {
            return true;
          }

          return false;
        });

        return !isOk;
      }
    ),
  domain: Yup.string().required("domain không được để trống"),
  api_tunnel: Yup.string().required("api tunnel không được để trống"),
  secret_key: Yup.string().required("secret key không được để trống"),
});
