import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import Button from "../button";
import Input from "../input";
import { createRef, useEffect, useState } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import CategoryService from "@app/services/http/category.service";
import { CustomSelect } from "../select/custom-select";
import TriangleDownArrow from "../select/triangle-down-arrow";
import { NormalSelectOptions } from "../select/select.type";
import { createPackageValidationSchema } from "@app/validations";
import { CreatePackageRequest, PackageInitialValues } from "@app/types";
import PackageService from "@app/services/http/package.service";

function CreatePackageModal({ portalDialogRef }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<PackageInitialValues>>();

  const [categories, setCategories] = useState<NormalSelectOptions>([]);

  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(CategoryService.getCategories(), (categories) => {
      if (categories.length) {
        const categoriesDropdown: NormalSelectOptions = categories.map(
          (category) => ({
            label: category.name,
            value: category._id,
          })
        );

        setCategories(categoriesDropdown);
      } else {
        setCategories([]);
      }
    });
  }, []);

  const handleSubmit = (values: PackageInitialValues) => {
    const createPackageReq: CreatePackageRequest = {
      group: values.group,
      name: values.name,
      price_per_hour: Number(values.price_per_hour),
      price_per_day: Number(values.price_per_day),
      price_per_month: Number(values.price_per_month),
      cpu: values.cpu,
      gpu: values.gpu,
      ram: values.ram,
      ssd: values.ssd,
      box_phone: values.box_phone,
    };

    subscribeOnce(
      PackageService.createPackage(values.categoryId, createPackageReq),
      (packageRes) => {
        portalDialogRef.close({ packageRes });
      }
    );
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] p-5">
        <div className="font-bold text-xl">Thêm gói</div>
        <Formik
          initialValues={{
            group: "",
            name: "",
            price_per_hour: "",
            price_per_day: "",
            price_per_month: "",
            cpu: "",
            gpu: "",
            ram: "",
            ssd: "",
            categoryId: "",
            box_phone: "",
          }}
          validationSchema={createPackageValidationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="flex justify-between gap-5">
              <div className="flex-1">
                <div className="mt-3">
                  <div className="text-sm">Tên nhóm</div>
                  <FormControl name="group">
                    <Input
                      className="mt-1 !max-w-none text-sm"
                      errorClassName="text-sm"
                    />
                  </FormControl>
                </div>
                <div className="mt-3">
                  <div className="text-sm">Giá mỗi giờ</div>
                  <FormControl name="price_per_hour">
                    <Input
                      className="mt-1 !max-w-none text-sm"
                      errorClassName="text-sm"
                    />
                  </FormControl>
                </div>
                <div className="mt-3">
                  <div className="text-sm">Giá mỗi ngày</div>
                  <FormControl name="price_per_day">
                    <Input
                      className="mt-1 !max-w-none text-sm"
                      errorClassName="text-sm"
                    />
                  </FormControl>
                </div>
                <div className="mt-3">
                  <div className="text-sm">Giá mỗi tháng</div>
                  <FormControl name="price_per_month">
                    <Input
                      className="mt-1 !max-w-none text-sm"
                      errorClassName="text-sm"
                    />
                  </FormControl>
                </div>
                <div className="mt-3">
                  <div className="text-sm">CPU</div>
                  <FormControl name="cpu">
                    <Input
                      className="mt-1 !max-w-none text-sm"
                      errorClassName="text-sm"
                    />
                  </FormControl>
                </div>
                <div className="mt-3">
                  <div className="text-sm">GPU</div>
                  <FormControl name="gpu">
                    <Input
                      className="mt-1 !max-w-none text-sm"
                      errorClassName="text-sm"
                    />
                  </FormControl>
                </div>
              </div>
              <div className="flex-1">
                <div className="mt-3">
                  <div className="text-sm">Tên gói</div>
                  <FormControl name="name">
                    <Input
                      className="mt-1 !max-w-none text-sm"
                      errorClassName="text-sm"
                    />
                  </FormControl>
                </div>
                <div className="mt-3">
                  <div className="text-sm">RAM</div>
                  <FormControl name="ram">
                    <Input
                      className="mt-1 !max-w-none text-sm"
                      errorClassName="text-sm"
                    />
                  </FormControl>
                </div>
                <div className="mt-3">
                  <div className="text-sm">SSD</div>
                  <FormControl name="ssd">
                    <Input
                      className="mt-1 !max-w-none text-sm"
                      errorClassName="text-sm"
                    />
                  </FormControl>
                </div>
                <div className="mt-3">
                  <div className="text-sm">Danh mục</div>
                  <FormControl name="categoryId">
                    <CustomSelect
                      options={categories}
                      width="100%"
                      className="!h-9 !border-[#3A466480] !rounded-lg"
                      optionContainerClassName="top-[34px] w-full"
                      optionClassName="!min-h-[35px] text-sm text-black whitespace-nowrap px-2"
                      labelSelectedClassName="text-black text-sm"
                      errorClassName="!text-[#D60000B2] text-sm"
                      arrow={<TriangleDownArrow />}
                      hasBgColorHover
                    />
                  </FormControl>
                </div>
                <div className="mt-3">
                  <div className="text-sm">Box phone</div>
                  <FormControl name="box_phone">
                    <Input
                      className="mt-1 !max-w-none text-sm"
                      errorClassName="text-sm"
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              label="Thêm"
              containerClassName="mt-5 mx-auto"
              width="fit-content"
              className="px-6 text-sm"
              labelClassName="font-bold"
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default CreatePackageModal;
