import { NormalSelectOptions } from "@app/components/select/select.type";

export enum OrderStatus {
  FINISHED = 0,
  BUSY = 1,
}

export const OrderStatusName = {
  [OrderStatus.FINISHED]: "Kết Thúc",
  [OrderStatus.BUSY]: "Đang Thuê",
};

export const FilterStatusOptions: NormalSelectOptions = [
  {
    label: "Tất cả",
    value: -1,
  },
  {
    label: OrderStatusName[OrderStatus.FINISHED],
    value: OrderStatus.FINISHED,
  },
  {
    label: OrderStatusName[OrderStatus.BUSY],
    value: OrderStatus.BUSY,
  },
];

export enum OrderExtend {
  NO = 0,
  YES = 1,
}

export const OrderExtendName = {
  [OrderExtend.NO]: "Không",
  [OrderExtend.YES]: "Có",
};
