import { Category } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _CategoryService {
  public getCategories() {
    return HttpService.get<Category[]>("/categories");
  }

  public createCategory(name: string) {
    return HttpService.post<Category[]>("/categories", {
      body: {
        name,
      },
    });
  }

  public deleteCategory(categoryId: string) {
    return HttpService.delete<Category[]>(`/categories/${categoryId}`);
  }
}

const CategoryService = new _CategoryService();

export default CategoryService;
