import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  username: Yup.string().required("Username must not be blank"),
  password: Yup.string().required("Password must not be blank"),
});

export const signUpValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Don't leave your username blank !")
    .min(3, "Your username is too short. Try something longer")
    .max(20, "Are you sure this is your username? It is too long to memorize"),
  email: Yup.string()
    .required("We will serve you better with your email. Fill it up !")
    .email("It seems to be not an email address!"),
  phone: Yup.string()
    .required("Phone numbers are required for us to assist you when needed")
    .matches(
      /^[0-9]{10,12}$/,
      "Are you sure you are entering the phone number"
    ),
  password: Yup.string().required("Don't leave your password blank !"),
  confirmPassword: Yup.string()
    .required("Don't leave your password blank !")
    .oneOf(
      [Yup.ref("password")],
      "The password and confirm password do not match"
    ),
});
