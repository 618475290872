import { DEFAULT_PAGE, OrderStatus } from "@app/constants";
import { OrderResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _OrderService {
  public getOrders(
    page = DEFAULT_PAGE,
    search = "",
    username = "",
    fromDate = "",
    toDate = "",
    status?: OrderStatus
  ) {
    const queryParams: any = {
      page,
      search,
      username,
      fromDate,
      toDate,
    };

    if (typeof status === "string" && status !== "-1") {
      queryParams.status = status;
    }

    return HttpService.get<OrderResponse>("/orders", {
      queryParams,
    });
  }
  public getExcelFileOrders(
    search = "",
    username = "",
    fromDate = "",
    toDate = "",
    status?: OrderStatus
  ) {
    const queryParams: any = {
      search,
      username,
      fromDate,
      toDate,
    };

    if (typeof status === "string" && status !== "-1") {
      queryParams.status = status;
    }

    return HttpService.requestDownload("/orders/orders_file", queryParams);
  }
}

const OrderService = new _OrderService();

export default OrderService;
