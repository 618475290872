import { DEFAULT_PAGE } from "@app/constants";
import { IPResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _IPService {
  public getIPs(
    page = DEFAULT_PAGE,
    username = "",
    ipv4 = "",
    fromDate = "",
    toDate = ""
  ) {
    return HttpService.get<IPResponse>("/ips", {
      queryParams: {
        page,
        username,
        ipv4,
        fromDate,
        toDate,
      },
    });
  }
}

const IPService = new _IPService();

export default IPService;
