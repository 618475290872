import Button from "@app/components/button";
import ConfirmModal from "@app/components/confirm-modal";
import CreateServerModal from "@app/components/server-modal";
import { addToast } from "@app/components/toast/toast.service";
import {
  CURRENT_ACCOUNT,
  Role,
  ServerStatus,
  ServerStatusName,
  SystemMessage,
} from "@app/constants";
import PcService from "@app/services/http/pc.service";
import ServerService from "@app/services/http/server.service";
import { openPortalDialog } from "@app/services/modal.service";
import { Pc, Server, UpdateServerStatusRequest } from "@app/types";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import StorageService from "@core/services/storage";
import clsx from "clsx";
import { MouseEvent, useEffect, useState } from "react";

function PcManagement() {
  const [servers, setServers] = useState<Server[]>([]);
  const [pcs, setPCs] = useState<Pc[]>([]);
  const [domainSelected, setDomainSelected] = useState("");

  const currentAccount = StorageService.getObject(CURRENT_ACCOUNT) as any;

  const [update, forceUpdate] = useForceUpdate();
  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(ServerService.getServers(), (servers) => {
      if (servers.length) {
        setServers(servers);

        if (!domainSelected) {
          setDomainSelected(servers[0].domain);
        }
      } else {
        setServers([]);
        setDomainSelected("");
      }
    });
  }, [update]);

  useEffect(() => {
    if (domainSelected) {
      subscribeUntilDestroy(PcService.getPCs(domainSelected), (res) => {
        if (res.data.length) {
          setPCs(res.data);
        } else {
          setPCs([]);
        }
      });
    } else {
      setPCs([]);
    }
  }, [domainSelected]);

  const getCurrentServerName = () => {
    const server = servers.find((server) => server.domain === domainSelected);

    return server?.name ?? "";
  };

  const handleCreateServer = () => {
    const createServerModalObs = openPortalDialog(CreateServerModal);

    createServerModalObs.afterClosed().subscribe((data) => {
      if (data?.server) {
        addToast({ text: SystemMessage.ADD_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  const handleServerClick = (domain: string) => {
    setDomainSelected(domain);
  };

  const handleWolClick = (macAddress: string) => {
    if (macAddress && domainSelected) {
      const wolObs = openPortalDialog(ConfirmModal, {
        message: "Bạn có chắc chắn wol không?",
      });

      wolObs.afterClosed().subscribe((data) => {
        if (data?.isAccept) {
          subscribeOnce(PcService.wol(macAddress, domainSelected), (data) => {
            if (data.message) {
              addToast({ text: data.message });
              forceUpdate();
            }
          });
        }
      });
    }
  };

  const handleResetClick = (ipAddress: string) => {
    if (ipAddress && domainSelected) {
      const resetObs = openPortalDialog(ConfirmModal, {
        message: "Bạn có chắc chắn Reset không?",
      });

      resetObs.afterClosed().subscribe((data) => {
        if (data?.isAccept) {
          subscribeOnce(PcService.reset(ipAddress), (data) => {
            if (data.message) {
              addToast({ text: data.message });
              forceUpdate();
            }
          });
        }
      });
    }
  };

  const handleShutDownClick = (ipAddress: string) => {
    if (ipAddress && domainSelected) {
      const shutDownObs = openPortalDialog(ConfirmModal, {
        message: "Bạn có chắc chắn tắt server này không?",
      });

      shutDownObs.afterClosed().subscribe((data) => {
        if (data?.isAccept) {
          subscribeOnce(
            PcService.shutDown(ipAddress, domainSelected),
            (data) => {
              if (data.message) {
                addToast({ text: data.message });
                forceUpdate();
              }
            }
          );
        }
      });
    }
  };

  const handleReConnectClick = (ipAddress: string) => {
    if (ipAddress && domainSelected) {
      const shutDownObs = openPortalDialog(ConfirmModal, {
        message: "Bạn có chắc chắn kết nối lại",
      });

      shutDownObs.afterClosed().subscribe((data) => {
        if (data?.isAccept) {
          const body = {
            domain: domainSelected,
            username: currentAccount.username,
            ipaddress: ipAddress,
          };

          subscribeOnce(PcService.reConnect(body), (data) => {
            if (data.message) {
              addToast({ text: data.message });
              forceUpdate();
            }
          });
        }
      });
    }
  };

  const handleServerStatusClick = (
    event: MouseEvent<HTMLButtonElement>,
    serverId: string,
    currentStatus: ServerStatus
  ) => {
    event.stopPropagation();

    if (!serverId) return;

    const message = `Bạn có muốn ${
      currentStatus === ServerStatus.ON ? "tắt" : "bật"
    } server này không?`;

    const updateServerStatusObs = openPortalDialog(ConfirmModal, {
      message,
    });

    const updateServerStatusRequest: UpdateServerStatusRequest = {
      status:
        currentStatus === ServerStatus.ON ? ServerStatus.OFF : ServerStatus.ON,
    };

    updateServerStatusObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        subscribeOnce(
          ServerService.updateServerStatus(serverId, updateServerStatusRequest),
          () => {
            addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
            forceUpdate();
          }
        );
      }
    });
  };

  const handleDeleteServerClick = (
    event: MouseEvent<HTMLButtonElement>,
    serverId: string
  ) => {
    event.stopPropagation();

    if (!serverId) return;

    const deleteServerObs = openPortalDialog(ConfirmModal, {
      message: "Bạn có muốn xóa server này không?",
    });

    deleteServerObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        subscribeOnce(ServerService.deleteServer(serverId), () => {
          addToast({ text: SystemMessage.DELETE_SUCCESSFULLY });
          forceUpdate();
        });
      }
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="pb-2 px-2 border-b border-[#409EFF]">
        <div className="flex justify-end">
          {currentAccount.role !== Role.STAFF && (
            <Button
              label="Thêm Server"
              width="fit-content"
              className="px-2 text-sm"
              onClick={handleCreateServer}
            />
          )}
        </div>
        <div className="mt-2 flex gap-3 max-w-full overflow-x-auto pb-2">
          {!!servers.length &&
            servers.map((server) => (
              <div
                key={server._id}
                className={clsx(
                  "text-sm bg-blue-300 p-2 rounded cursor-pointer hover:bg-gray-300",
                  {
                    "!bg-gray-300": server.domain === domainSelected,
                  }
                )}
                onClick={() => handleServerClick(server.domain)}
              >
                <div>
                  <span className="font-bold">Name: </span>
                  <span className="font-bold">{server.name}</span>
                </div>
                <div>
                  <span>Domain: </span>
                  {server.domain}
                </div>
                <div className="flex items-center gap-1">
                  <div>
                    <span>Status: </span>
                  </div>
                  <Button
                    label={ServerStatusName[server.status]}
                    theme={
                      server.status === ServerStatus.ON ? "primary" : "danger"
                    }
                    size="xs"
                    width="fit-content"
                    className="px-2 !h-[24px] !leading-[24px]"
                    onClick={(event) =>
                      handleServerStatusClick(event, server._id, server.status)
                    }
                  />
                </div>
                <div>
                  {currentAccount.role !== Role.STAFF && (
                    <Button
                      label="Xóa"
                      theme="danger"
                      size="xs"
                      width="fit-content"
                      className="px-2 !h-[24px] !leading-[24px]"
                      onClick={(event) =>
                        handleDeleteServerClick(event, server._id)
                      }
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-72">IP</th>
                <th className="p-3 text-left w-72">MAC</th>
                <th className="p-3 text-left w-32">Group</th>
                <th className="p-3 text-left w-40">Trạng thái (inuse)</th>
                <th className="p-3 text-left w-40">Server</th>
                <th className="p-3 text-left w-40">Owner</th>
                <th className="p-3 text-left w-40">Reset</th>
                <th className="p-3 text-left w-40">Wol</th>
                <th className="p-3 text-left w-40">Shutdown</th>
                <th className="p-3 text-left w-40">Kết nối lại</th>
              </tr>
            </thead>
            <tbody>
              {!!pcs.length &&
                pcs.map((pc) => (
                  <tr
                    key={pc.ipaddress}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">
                      {pc.ipaddress}
                      <br />
                      {pc.extip}
                    </td>
                    <td className="p-3">{pc.macaddress}</td>
                    <td className="p-3">{pc.group}</td>
                    <td className="p-3">{pc.inuse ? "TRUE" : "FALSE"}</td>
                    <td className="p-3">{getCurrentServerName()}</td>
                    <td className="p-3">{pc.owner}</td>
                    <td className="p-3">
                      <Button
                        label="Reset"
                        width="fit-content"
                        className="px-2 text-sm"
                        onClick={() => handleResetClick(pc.ipaddress)}
                      />
                    </td>
                    <td className="p-3">
                      <Button
                        label="Wol"
                        width="fit-content"
                        className="px-2 text-sm"
                        onClick={() => handleWolClick(pc.macaddress)}
                      />
                    </td>
                    <td className="p-3">
                      <Button
                        label="Shutdown"
                        width="fit-content"
                        className="px-2 text-sm"
                        onClick={() => handleShutDownClick(pc.ipaddress)}
                      />
                    </td>
                    <td className="p-3">
                      <Button
                        label="K.nối lại"
                        width="fit-content"
                        className="px-2 text-sm"
                        onClick={() => handleReConnectClick(pc.ipaddress)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!pcs.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PcManagement;
