import { DEFAULT_PAGE } from "@app/constants";
import { Image, ImageResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _ImageService {
  public getImages(
    page = DEFAULT_PAGE,
    username = "",
    id_image = "",
    group = "",
    status = "", 
  ) {
    const bd: any = {
      page,
      username,
      id_image,
      group,
    };

    if (status === "0" || status === "1") {
      bd.is_booting = +status;
    }

    if (status === "2") {
      bd.is_installed = 1;
    }

    return HttpService.get<ImageResponse>("/images/admin-handle", {
      queryParams: bd,
    });
  }

  public deleteImage(id_image = "") {
    return HttpService.delete<Image>(`/images/admin-handle/${id_image}`);
  }
}

const ImageService = new _ImageService();

export default ImageService;
