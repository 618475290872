import { Bank, CreateBankRequest } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _BankService {
  public getBanks() {
    return HttpService.get<Bank[]>("/banks");
  }

  public createBank(createBankRequest: CreateBankRequest) {
    return HttpService.post<Bank>("/banks", {
      body: {
        ...createBankRequest,
      },
    });
  }

  public deleteBank(bankId: string) {
    return HttpService.delete<Bank>(`/banks/${bankId}`);
  }
}

const BankService = new _BankService();

export default BankService;
