import { NormalSelectOptions } from "@app/components/select/select.type";

export enum KycStatus {
  NO_VERIFY_REQUIRED = 0,
  NEED_VERIFY = 1,
}

export enum VerifyStatus {
  PENDING = 0,
  SUCCESS = 1,
  FAILED = 2,
}

export const VerifyStatusName = {
  [VerifyStatus.PENDING]: "Chờ duyệt",
  [VerifyStatus.SUCCESS]: "Thành công",
  [VerifyStatus.FAILED]: "Thất bại",
};

export const VerifyStatusOptions: NormalSelectOptions = [
  {
    label: VerifyStatusName[VerifyStatus.PENDING],
    value: VerifyStatus.PENDING,
  },
  {
    label: VerifyStatusName[VerifyStatus.SUCCESS],
    value: VerifyStatus.SUCCESS,
  },
  {
    label: VerifyStatusName[VerifyStatus.FAILED],
    value: VerifyStatus.FAILED,
  },
];

export enum ActiveStatus {
  NOT_ACTIVE = 0,
  ACTIVATED = 1,
}
