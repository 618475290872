import {
  CreateServerRequest,
  Server,
  UpdateServerStatusRequest,
} from "@app/types";
import HttpService from "@core/services/http/http.service";

class _ServerService {
  public getServers() {
    return HttpService.get<Server[]>("/servers");
  }

  public createServer(createServerRequest: CreateServerRequest) {
    return HttpService.post<Server>("/servers", {
      body: {
        ...createServerRequest,
      },
    });
  }

  public updateServerStatus(
    serverId: string,
    updateServerStatusRequest: UpdateServerStatusRequest
  ) {
    return HttpService.patch<Server>(`/servers/${serverId}`, {
      body: {
        ...updateServerStatusRequest,
      },
    });
  }

  public deleteServer(serverId: string) {
    return HttpService.delete<Server>(`/servers/${serverId}`);
  }
}

const ServerService = new _ServerService();

export default ServerService;
