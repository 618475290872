import Button from "@app/components/button";
import CreateCategoryModal from "@app/components/category-modal";
import ConfirmModal from "@app/components/confirm-modal";
import { addToast } from "@app/components/toast/toast.service";
import { SystemMessage } from "@app/constants";
import CategoryService from "@app/services/http/category.service";
import { openPortalDialog } from "@app/services/modal.service";
import { Category } from "@app/types";
import { formatDate } from "@app/utils/util";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";

function CategoryManagement() {
  const [categories, setCategories] = useState<Category[]>([]);

  const [update, forceUpdate] = useForceUpdate();
  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(CategoryService.getCategories(), (categories) => {
      if (categories.length) {
        setCategories(categories);
      } else {
        setCategories([]);
      }
    });
  }, [update]);

  const handleCreateCategory = () => {
    const createCategoryModalObs = openPortalDialog(CreateCategoryModal);

    createCategoryModalObs.afterClosed().subscribe((data) => {
      if (data?.category) {
        addToast({ text: SystemMessage.ADD_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  const handleDeleteCategory = (categoryId: string) => {
    const deleteCategoryObs = openPortalDialog(ConfirmModal, {
      message: "Bạn có chắc chắn xóa danh mục này không?",
    });

    deleteCategoryObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        subscribeOnce(CategoryService.deleteCategory(categoryId), () => {
          addToast({ text: SystemMessage.DELETE_SUCCESSFULLY });
          forceUpdate();
        });
      }
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="pb-2 px-2 border-b border-[#409EFF]">
        <div className="flex justify-end">
          <Button
            label="Thêm danh mục"
            width="fit-content"
            className="px-2 text-sm"
            onClick={handleCreateCategory}
          />
        </div>
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-72">Tên danh mục</th>
                <th className="p-3 text-left w-72">Ngày tạo</th>
                <th className="p-3 text-left w-72">Hành động</th>
              </tr>
            </thead>
            <tbody>
              {!!categories.length &&
                categories.map((category) => (
                  <tr
                    key={category._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">{category.name}</td>
                    <td className="p-3">{formatDate(category.createdAt)}</td>
                    <td className="p-3">
                      <Button
                        label="Xóa danh mục"
                        width="fit-content"
                        className="px-2 text-sm"
                        onClick={() => handleDeleteCategory(category._id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!categories.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CategoryManagement;
