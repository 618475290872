import BalanceAccount from "@app/components/balance-account";
import CreateAccountModel from "@app/components/create-account-modal";
import ImageAllowed from "@app/components/image-allowed";
import Input from "@app/components/input";
import Pagination from "@app/components/pagination";
import ReasonKycModal from "@app/components/reason-kyc-modal/reason-kyc-modal.component";
import { Select } from "@app/components/select/select";
import { addToast } from "@app/components/toast/toast.service";
import ViewImageModal from "@app/components/view-image-modal";
import {
  ActiveStatus,
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
  KycStatus,
  SystemMessage,
  VerifyStatus,
  VerifyStatusOptions,
} from "@app/constants";
import AccountService from "@app/services/http/account.service";
import { openPortalDialog } from "@app/services/modal.service";
import { Account, VerifyAccountRequest } from "@app/types";
import { formatDate } from "@app/utils/util";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { Switch } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Subject, debounceTime } from "rxjs";

function Home() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [search, setSearch] = useState("");

  const searchSubject = new Subject<string>();

  const [update, forceUpdate] = useForceUpdate();
  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    searchSubject.pipe(debounceTime(300)).subscribe((data) => {
      setSearch(data);
    });
  }, [searchSubject]);

  useEffect(() => {
    subscribeUntilDestroy(
      AccountService.getAccounts(page, search),
      (accounts) => {
        if (accounts.data.length) {
          setAccounts(accounts.data);
          setTotalPages(accounts.total_page);
        } else {
          setAccounts([]);
          setTotalPages(DEFAULT_TOTAL_PAGES);
        }
      }
    );
  }, [page, search, update]);

  const handleChangeKycStatus = (userId: string, isChecked: boolean) => {
    const kyc = isChecked
      ? KycStatus.NEED_VERIFY
      : KycStatus.NO_VERIFY_REQUIRED;

    subscribeOnce(AccountService.verifyAccount(userId, { kyc }), () => {
      addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
      forceUpdate();
    });
  };

  const handleVerifyStatusChange = (userId: string, value: string) => {
    const verifyStatus = Number(value) as VerifyStatus;

    if (verifyStatus !== VerifyStatus.FAILED) {
      subscribeOnce(
        AccountService.verifyAccount(userId, { isVerify: verifyStatus }),
        () => {
          addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
          forceUpdate();
        }
      );
    } else {
      const reasonKycModalObs = openPortalDialog(ReasonKycModal);

      reasonKycModalObs.afterClosed().subscribe((data) => {
        if (data?.reason) {
          const verifyAccountRequest: VerifyAccountRequest = {
            isVerify: verifyStatus,
            reasonFailKyc: data.reason,
          };

          subscribeOnce(
            AccountService.verifyAccount(userId, verifyAccountRequest),
            () => {
              addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
              forceUpdate();
            }
          );
        }
      });
    }
  };

  const handleChangeActiveStatus = (userId: string, isChecked: boolean) => {
    const isActive = isChecked
      ? ActiveStatus.ACTIVATED
      : ActiveStatus.NOT_ACTIVE;

    subscribeOnce(AccountService.verifyAccount(userId, { isActive }), () => {
      addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
      forceUpdate();
    });
  };

  const onForceUpdate = () => {
    addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
    forceUpdate();
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const handleViewImage = (href: string) => {
    const viewImageModalObs = openPortalDialog(ViewImageModal, { href });

    viewImageModalObs.afterClosed().subscribe(() => {});
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    searchSubject.next(e.target.value);
  };

  const handleCreateAccount = () => {
    const createAccountObs = openPortalDialog(CreateAccountModel);

    createAccountObs.afterClosed().subscribe((data) => {
      if (data?.account) {
        addToast({ text: SystemMessage.ADD_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="flex justify-between pb-2 px-2 border-b border-[#409EFF]">
        <Input
          className="mt-0 max-w-[320px]"
          placeholder="Nhập username, phone hoặc email hoặc quyền (staff, user)"
          onChange={onFilterChange}
        />
        <button
          className="bg-[#409EFF] p-2 text-center mr-5 rounded-lg text-white max-md:text-[12px]"
          onClick={handleCreateAccount}
        >
          Tạo tài khoản nhân viên
        </button>
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-52">Tài khoản</th>
                <th className="p-3 text-left w-52">KYC</th>
                <th className="p-3 text-left w-52">Email</th>
                <th className="p-3 text-left w-52">Số dư</th>
                <th className="p-3 text-left w-36">CCCD M.Trước</th>
                <th className="p-3 text-left w-36">CCCD M.Sau</th>
                <th className="p-3 text-left w-36">Chân dung</th>
                <th className="p-3 text-left w-36">Hợp đồng</th>
                <th className="p-3 text-left w-52">Thời gian xác minh</th>
                <th className="p-3 text-left w-52">Image cho phép</th>
                <th className="p-3 text-left w-52">Active</th>
                <th className="p-3 text-left w-28">Yêu cầu xác minh</th>
                <th className="p-3 text-left w-28">Đã xác minh</th>
              </tr>
            </thead>
            <tbody>
              {!!accounts.length &&
                accounts.map((account) => (
                  <tr
                    key={account._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">
                      <div>{account.username}</div>
                      <div>{account.fullName}</div>
                      <div>{formatDate(account.createdAt)}</div>
                    </td>
                    <td className="p-3">
                      {account.is_up
                        ? account.is_up === 1
                          ? "Đã up"
                          : "Chờ up"
                        : "Chưa up"}
                    </td>
                    <td className="p-3">
                      <div>{account.email}</div>
                      <div>{account.phone}</div>
                    </td>
                    <td className="p-3">
                      <BalanceAccount
                        account={account}
                        forceUpdate={onForceUpdate}
                      />
                    </td>
                    <td className="p-3">
                      <div
                        className="text-blue-500 cursor-pointer hover:underline"
                        onClick={() => handleViewImage(account.front_card)}
                      >
                        Xem
                      </div>
                    </td>
                    <td className="p-3">
                      <div
                        className="text-blue-500 cursor-pointer hover:underline"
                        onClick={() => handleViewImage(account.back_card)}
                      >
                        Xem
                      </div>
                    </td>
                    <td className="p-3">
                      <div
                        className="text-blue-500 cursor-pointer hover:underline"
                        onClick={() => handleViewImage(account.portrait)}
                      >
                        Xem
                      </div>
                    </td>
                    <td className="p-3">
                      {!!account.link_contract && (
                        <a
                          href={account.link_contract}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          Xem
                        </a>
                      )}
                    </td>
                    <td className="p-3">{formatDate(account.timeKyc)}</td>
                    <td className="p-3">
                      <ImageAllowed
                        account={account}
                        forceUpdate={onForceUpdate}
                      />
                    </td>
                    <td className="p-3">
                      <Switch
                        checked={account.isActive === ActiveStatus.ACTIVATED}
                        onChange={(e) => {
                          handleChangeActiveStatus(
                            account._id,
                            e.target.checked
                          );
                        }}
                      />
                    </td>
                    <td className="p-3">
                      <Switch
                        checked={account.kyc === KycStatus.NEED_VERIFY}
                        onChange={(e) => {
                          handleChangeKycStatus(account._id, e.target.checked);
                        }}
                      />
                    </td>
                    <td className="p-3">
                      <Select
                        width={95}
                        className="h-6 !mt-0"
                        options={VerifyStatusOptions}
                        value={account.isVerify}
                        defaultValue={account.isVerify}
                        onChange={(e) =>
                          handleVerifyStatusChange(account._id, e.target.value)
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!accounts.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
      {totalPages > 0 && (
        <div className="flex justify-center mt-3">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default Home;
