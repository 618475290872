import { NormalSelectOptions } from "@app/components/select/select.type";

export enum RechargeState {
  PENDING = 0,
  SUCCESSFULLY = 1,
  CANCELLED = 2,
  TIMEOUT = 3,
}

export const RechargeStateName = {
  [RechargeState.PENDING]: "Chờ xử lý",
  [RechargeState.SUCCESSFULLY]: "Thành công",
  [RechargeState.CANCELLED]: "Đã hủy",
  [RechargeState.TIMEOUT]: "Hết thời gian",
};

export const FilterRechargeStatusOptions: NormalSelectOptions = [
  {
    label: "Tất cả",
    value: -1,
  },
  {
    label: RechargeStateName[RechargeState.PENDING],
    value: RechargeState.PENDING,
  },
  {
    label: RechargeStateName[RechargeState.SUCCESSFULLY],
    value: RechargeState.SUCCESSFULLY,
  },
  {
    label: RechargeStateName[RechargeState.CANCELLED],
    value: RechargeState.CANCELLED,
  },
  {
    label: RechargeStateName[RechargeState.TIMEOUT],
    value: RechargeState.TIMEOUT,
  },
];
