import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import Input from "../input";
import Button from "../button";
import { createSshValidationSchema } from "@app/validations";
import {
  SshInitialValues,
  CreateSshRequest,
  Server,
  UserGuideInitialValues,
  CreateUserGuideRequest,
} from "@app/types";
import { createRef, useEffect, useState } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import SshService from "@app/services/http/ssh.service";
import ServerService from "@app/services/http/server.service";
import { Select } from "../select/select";
import TextArea from "../textarea";
import UserGuideService from "@app/services/http/user.guide.service";
import { createUserGuideValidationSchema } from "@app/validations/user.guide.validation";

function CreateUserGuideModal({
  portalDialogRef,
  portalData,
}: PortalDialogProps) {
  const formRef = createRef<FormikContextType<UserGuideInitialValues>>();

  const { subscribeOnce } = useObservable();

  const handleSubmit = (values: UserGuideInitialValues) => {
    const createUserGuideReq: CreateUserGuideRequest = {
      title: values.title,
      link: values.link,
    };

    subscribeOnce(
      UserGuideService.createUserGuide(createUserGuideReq),
      (userGuide) => {
        portalDialogRef.close({ userGuide });
      }
    );
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] h-[700px] overflow-scroll p-5 max-sm:w-[300px]">
        <div className="font-bold text-xl">
          {portalData ? "Update SSH" : "Thêm SSH"}
        </div>
        <Formik
          initialValues={{
            title: portalData ? portalData.title : "",
            link: portalData ? portalData.link : "",
          }}
          validationSchema={createUserGuideValidationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="mt-3">
              <div className="text-sm">Title</div>
              <FormControl name="title">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <div className="mt-3">
              <div className="text-sm">Link</div>
              <FormControl name="link">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <Button
              type="submit"
              label={portalData ? "Update" : "Thêm"}
              containerClassName="mt-5 mx-auto"
              width="fit-content"
              className="px-6 text-sm"
              labelClassName="font-bold"
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default CreateUserGuideModal;
