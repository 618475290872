import {
  Account,
  RechargeWithdrawInitialValues,
  UpdateBalanceRequest,
} from "@app/types";
import Input from "../input";
import Button from "../button";
import { createRef } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import AccountService from "@app/services/http/account.service";
import { formatMoneyVN, isNumber } from "@app/utils/util";
import {
  PortalDialogProps,
  openPortalDialog,
} from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { rechargeWithdrawValidationSchema } from "@app/validations";
import { addToast } from "../toast/toast.service";
import { FormControl } from "../form-control";

function RechargeWithdrawModal({ portalDialogRef }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<RechargeWithdrawInitialValues>>();

  const handleSubmit = (values: RechargeWithdrawInitialValues) => {
    portalDialogRef.close({ money: values.money, content: values.content });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[400px] p-5">
        <div className="font-bold text-xl">Nạp/Rút</div>
        <Formik
          initialValues={{ money: 0, content: "" }}
          validationSchema={rechargeWithdrawValidationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="mt-4">Nhập số tiền:</div>
            <FormControl name="money">
              <Input
                className="mt-1 !max-w-none text-sm"
                type="number"
                errorClassName="text-sm"
              />
            </FormControl>
            <div className="mt-4">Nhập nội dung:</div>
            <FormControl name="content">
              <Input
                className="mt-1 !max-w-none text-sm"
                errorClassName="text-sm"
              />
            </FormControl>
            <div className="mt-5">
              <Button
                label="Lưu"
                type="submit"
                width="fit-content"
                containerClassName="mx-auto"
                className="px-10 !bg-[#409EFF] hover:!bg-[#66B1FF] !rounded-[20px]"
                labelClassName="font-medium text-sm"
              />
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

interface BalanceAccountProps {
  account: Account;

  forceUpdate: () => void;
}

function BalanceAccount({ account, forceUpdate }: BalanceAccountProps) {
  const { subscribeOnce } = useObservable();

  const onRechargeWithdrawClick = () => {
    const rechargeWithdrawModal = openPortalDialog(RechargeWithdrawModal);

    rechargeWithdrawModal.afterClosed().subscribe((data) => {
      if (isNumber(data?.money) && data?.content) {
        const updateBalanceRequest: UpdateBalanceRequest = {
          money: Number(data.money),
          content: data.content,
        };

        subscribeOnce(
          AccountService.updateBalance(account._id, updateBalanceRequest),
          () => {
            if (Number(data.money) >= 0) {
              addToast({ text: "Nạp thành công" });
            } else {
              addToast({ text: "Rút thành công" });
            }

            forceUpdate();
          }
        );
      }
    });
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <div>{formatMoneyVN(account.balance)}đ</div>
      <div>
        <Button label="Nạp/Rút" size="xs" onClick={onRechargeWithdrawClick} />
      </div>
    </div>
  );
}

export default BalanceAccount;
