import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import Input from "../input";
import Button from "../button";
import { createBankValidationSchema } from "@app/validations";
import { BankInitialValues, CreateBankRequest } from "@app/types";
import { createRef } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import BankService from "@app/services/http/bank.service";

function CreateBankModal({ portalDialogRef }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<BankInitialValues>>();

  const { subscribeOnce } = useObservable();

  const handleSubmit = (values: BankInitialValues) => {
    const createBankReq: CreateBankRequest = {
      bank_name: values.bankName,
      bank_number: values.bankNumber,
      bank_owner: values.bankOwner,
    };

    subscribeOnce(BankService.createBank(createBankReq), (bank) => {
      portalDialogRef.close({ bank });
    });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] p-5">
        <div className="font-bold text-xl">Thêm ngân hàng</div>
        <Formik
          initialValues={{ bankName: "", bankNumber: "", bankOwner: "" }}
          validationSchema={createBankValidationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="mt-3">
              <div className="text-sm">Tên ngân hàng</div>
              <FormControl name="bankName">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <div className="mt-3">
              <div className="text-sm">Số tài khoản</div>
              <FormControl name="bankNumber">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <div className="mt-3">
              <div className="text-sm">Chủ tài khoản</div>
              <FormControl name="bankOwner">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <Button
              type="submit"
              label="Thêm"
              containerClassName="mt-5 mx-auto"
              width="fit-content"
              className="px-6 text-sm"
              labelClassName="font-bold"
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default CreateBankModal;
