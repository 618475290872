import MenuLinkItem from "@app/components/menu-link-item";
import {
  ACCESS_TOKEN_KEY,
  CURRENT_ACCOUNT,
  MenuAdmin,
  MenuStaff,
  Role,
} from "@app/constants";
import { clearUser } from "@app/store/auth/auth.action";
import { Images } from "@assets/images";
import StorageService from "@core/services/storage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import clsx from "clsx";

function MainLayout() {
  const [isShowActions, setIsShowActions] = useState(false);
  const [isShowSidebar, setIsShowSidebar] = useState(false);

  const currentAccount = StorageService.getObject(CURRENT_ACCOUNT) as any;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(clearUser());
    StorageService.remove(ACCESS_TOKEN_KEY);
    navigate("/login");
  };

  const onMenuItemClick = () => {
    setIsShowSidebar(!isShowSidebar);
  };

  return (
    <>
      <div>
        <div className="sticky top-0 left-0 w-full h-20 max-sm:h-12 bg-white flex justify-between px-4 z-10 border-b border-solid border-[#eee]">
          <div className="h-full items-center flex">
            <div>
              <img
                className="md:hidden w-8 max-sm:w-6 cursor-pointer"
                src={Images.MenuIcon.default}
                alt=""
                onClick={() => setIsShowSidebar(!isShowSidebar)}
              />
              <img
                className="max-md:hidden w-[180px] cursor-pointer"
                src={Images.LogoPcRender.default}
                alt=""
                onClick={() => navigate("/")}
              />
            </div>
          </div>
          <div className="h-full items-center flex">
            <div className="cursor-pointer relative">
              <img
                className="w-12 h-12 max-sm:w-8 max-sm:h-8"
                src={Images.AvatarDefaultIcon.default}
                alt=""
                onClick={() => setIsShowActions(!isShowActions)}
              />
              {isShowActions && (
                <div className="absolute top-14 max-sm:top-10 right-0 w-[119px] max-md:w-[150px] bg-white text-sm shadow-[0_4px_18px_0_rgb(0,0,0,15%)] rounded-[3px]">
                  <div
                    className="p-1 cursor-pointer hover:bg-[#1E86E5] hover:text-white rounded-b-[3px] text-[#3A4664]"
                    onClick={logout}
                  >
                    Đăng xuất
                  </div>
                  {currentAccount.role !== Role.STAFF && (
                    <div>
                      <div className="p-1 cursor-pointer hover:bg-[#1E86E5] hover:text-white rounded-b-[3px] text-[#3A4664]">
                        <a
                          href="https://api.pcrender.com/view/confirm-account"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Đổi mật khẩu
                        </a>
                      </div>
                      <div className="p-1 cursor-pointer hover:bg-[#1E86E5] hover:text-white rounded-b-[3px] text-[#3A4664]">
                        <a
                          href="https://api.pcrender.com/view/key-management"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Vô hiệu hóa JWT
                        </a>
                      </div>
                      <div className="p-1 cursor-pointer hover:bg-[#1E86E5] hover:text-white rounded-b-[3px] text-[#3A4664]">
                        <a
                          href="https://api.pcrender.com/api/accounts/total-balance"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Xem tổng số dư
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="max-md:ml-0 ml-[260px]">
          <Outlet />
        </div>
      </div>
      <div
        className={clsx(
          "transition-all max-md:left-[-260px] overflow-scroll pb-2 fixed top-20 max-sm:top-12 left-0 w-[260px] h-[calc(100%-64px)] max-sm:h-[calc(100%-32px)] bg-white border-r-[2px] border-solid border-[#eee]",
          {
            "max-md:left-[0px]": isShowSidebar,
          }
        )}
      >
        {currentAccount?.role === Role.STAFF
          ? MenuStaff.length &&
            MenuStaff.map((item, index) => (
              <MenuLinkItem
                key={index}
                {...item}
                onMenuItemClick={onMenuItemClick}
              />
            ))
          : MenuAdmin.length &&
            MenuAdmin.map((item, index) => (
              <MenuLinkItem
                key={index}
                {...item}
                onMenuItemClick={onMenuItemClick}
              />
            ))}
      </div>
    </>
  );
}

export default MainLayout;
