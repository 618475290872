import {
  UserGuide,
  CreateUserGuideRequest,
  UserGuideResponse,
} from "@app/types";
import HttpService from "@core/services/http/http.service";

class _UserGuideService {
  public getUserGuides(page: number) {
    return HttpService.get<UserGuideResponse>("/user-guide", {
      queryParams: {
        page: page,
      },
    });
  }

  public createUserGuide(createUserGuideReq: CreateUserGuideRequest) {
    return HttpService.post<UserGuide>("/user-guide", {
      body: {
        ...createUserGuideReq,
      },
    });
  }

  public deleteUserGuide(userGuideId: string) {
    return HttpService.delete<UserGuide>(`/user-guide/${userGuideId}`);
  }
}

const UserGuideService = new _UserGuideService();

export default UserGuideService;
