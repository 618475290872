import * as Yup from "yup";

export const createPackageValidationSchema = Yup.object().shape({
  group: Yup.string().required("Tên nhóm không được để trống"),
  name: Yup.string().required("Tên gói không được để trống"),
  price_per_hour: Yup.string()
    .required("Giá gói theo giờ không được để trống")
    .matches(/^\d+$/g, "Giá gói theo giờ không hợp lệ"),
  price_per_day: Yup.string()
    .required("Giá gói theo ngày không được để trống")
    .matches(/^\d+$/g, "Giá gói theo ngày không hợp lệ"),
  price_per_month: Yup.string()
    .required("Giá gói theo tháng không được để trống")
    .matches(/^\d+$/g, "Giá gói theo tháng không hợp lệ"),
  cpu: Yup.string().required("Thông tin CPU không được để trống"),
  gpu: Yup.string().required("Thông tin GPU không được để trống"),
  ram: Yup.string().required("Thông tin RAM không được để trống"),
  ssd: Yup.string().required("Thông tin SSD không được để trống"),
  categoryId: Yup.string().required("Danh mục không được để trống"),
});
