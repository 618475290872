import { Account, ImageAllowedRequest } from "@app/types";
import Input from "../input";
import Button from "../button";
import { ChangeEvent, useEffect, useState } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import AccountService from "@app/services/http/account.service";

interface ImageAllowedProps {
  account: Account;

  forceUpdate: () => void;
}

function ImageAllowed({ account, forceUpdate }: ImageAllowedProps) {
  const [imageAllowed, setImageAllowed] = useState(account.imageAllowed);

  const { subscribeOnce } = useObservable();

  useEffect(() => {
    setImageAllowed(account.imageAllowed);
  }, [account.imageAllowed]);

  const onImageAllowedChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(e.target.value))) {
      setImageAllowed(Number(e.target.value));
    }
  };

  const onSaveClick = () => {
    const imageAllowedRequest: ImageAllowedRequest = {
      imageAllowed,
    };

    subscribeOnce(
      AccountService.updateImageAllowed(account._id, imageAllowedRequest),
      () => {
        forceUpdate();
      }
    );
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <div>
        <Input
          type="number"
          className="mt-1 !max-w-none text-sm"
          errorClassName="text-sm"
          value={`${imageAllowed}`}
          onChange={onImageAllowedChange}
        />
      </div>
      <div>
        <Button label="Lưu" size="xs" onClick={onSaveClick} />
      </div>
    </div>
  );
}

export default ImageAllowed;
