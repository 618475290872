import { Role } from "@app/constants";
import { AppAction } from "@core/types/redux.type";
import { MyInfoActionType, MyInfoEpicType, MyInfoState } from "./my-info.type";

export function myInfoReducer(
  state = initialState,
  action: AppAction
): MyInfoState {
  switch (action.type) {
    case MyInfoEpicType.FETCH_MY_INFO:
      return {
        ...state,
        isMyInfoLoading: true,
        isMyInfoError: false,
      };
    case MyInfoActionType.STORE_MY_INFO:
      return {
        ...state,
        myInfo: {
          _id: action.payload?.myInfo?._id,
          username: action.payload?.myInfo?.username,
          fullName: action.payload?.myInfo?.fullName,
          email: action.payload?.myInfo?.email,
          isActive: action.payload?.myInfo?.isActive,
          phone: action.payload?.myInfo?.phone,
          balance: action.payload?.myInfo?.balance ?? 0,
          imageAllowed: action.payload?.myInfo?.imageAllowed ?? 0,
          kyc: action.payload?.myInfo?.kyc,
          isVerify: action.payload?.myInfo?.isVerify,
          front_card: action.payload?.myInfo?.front_card,
          back_card: action.payload?.myInfo?.back_card,
          portrait: action.payload?.myInfo?.portrait,
          link_contract: action.payload?.myInfo?.link_contract,
          otp: action.payload?.myInfo?.otp,
          timeKyc: action.payload?.myInfo?.timeKyc,
          role: action.payload?.myInfo?.role,
          createdAt: action.payload?.myInfo?.createdAt,
          is_up: action.payload?.myInfo.is_up,
          serversAccess: action.payload?.myInfo.serversAccess,
          updatedAt: action.payload?.myInfo?.updatedAt,
        },
        isMyInfoLoading: false,
        isMyInfoError: false,
      };
    case MyInfoActionType.CLEAR_MY_INFO:
      return {
        ...initialState,
        isMyInfoLoading: false,
      };
    case MyInfoActionType.FETCH_MY_INFO_FAILED:
      return {
        ...state,
        isMyInfoLoading: false,
        isMyInfoError: true,
      };
    default:
      return state;
  }
}

const initialState: MyInfoState = {
  myInfo: {
    _id: "",
    username: "",
    fullName: "",
    email: "",
    isActive: 0,
    phone: "",
    balance: 0,
    imageAllowed: 0,
    kyc: 0,
    isVerify: 0,
    front_card: "",
    back_card: "",
    portrait: "",
    link_contract: "",
    otp: "",
    timeKyc: "",
    role: Role.STAFF,
    is_up: 0,
    serversAccess: [],
    createdAt: "",
    updatedAt: "",
  },
  isMyInfoLoading: true,
  isMyInfoError: false,
};
